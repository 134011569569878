import { useEffect } from 'react'
import { ErrorPageV2 } from 'components/CheckoutMain/components/ErrorPageV2'

const Page404 = () => {
  useEffect(() => {
    window?.dataLayer?.push({
      event: 'page_view',
      tipo_pagina: 'Checkout Error',
      payment_type: '',
      card_type: '',
      error_value: '404'
    })
  }, [])

  return <ErrorPageV2 />
}

export default Page404
